import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import emailLogo from "../../assets/images/icons/forms/email.svg";
import phoneLogo from "../../assets/images/icons/forms/phone.svg";
import calendarLogo from "../../assets/images/icons/forms/date.svg";
import personLogo from "../../assets/images/icons/forms/firstname.svg";
import PhoneInput from "react-phone-number-input";
import { useToast } from "../../context/ToastContext";
import "./BookAppointment.scss";
import { createBookingNoLogin } from "../../api/bookings/bookingsApi";
import { ThreeDots } from "react-loader-spinner";

const BookAppointment = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const { t } = useTranslation("home");
  const [isLoading, setIsLoading] = useState(false);
  const { triggerToast } = useToast();

  const handleInstantCall = async (event) => {
    event.preventDefault();

    if (name.length > 0 && email.length > 0 && phone.length > 0) {
      const booking = {
        userId: 7,
        userName: name + " " + email,
        selectedPlatform: "wpp",
        location: "To be defined",
        link: `https://wa.me/${phone.replace("+", "")}`,
      };
      setIsLoading(true);
      await createBookingNoLogin(booking);
      setTimeout(() => {
        setIsLoading(false);
        triggerToast("Booking was created successfully!!");
      }, 1000);
    } else {
      triggerToast("Please provide required information");
    }
  };

  return (
    <div className="appointment-card">
      <h2>{t("BookAppointment.Title")}</h2>
      <form className="appointment-card-form">
        <div className="input-content mobile-hidden">
          <div className="label-content">
            <img src={personLogo} alt="name logo" />
            <label htmlFor="date">{t("BookAppointment.DateLabel")}</label>
          </div>
          <input
            type="text"
            value={name}
            id="booking-reg-name"
            onChange={(e) => setName(e.target.value)}
            placeholder={t("BookAppointment.DatePlaceholder")}
          />
        </div>
        <div className="input-content">
          <div className="label-content">
            <img src={emailLogo} alt="email logo" />
            <label htmlFor="email">{t("BookAppointment.EmailLabel")}</label>
          </div>
          <input
            type="email"
            value={email}
            id="booking-reg-email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("BookAppointment.EmailPlaceholder")}
          />
        </div>
        <div className="input-content">
          <div className="label-content">
            <img src={phoneLogo} alt="phone logo" />
            <label htmlFor="contact">{t("BookAppointment.ContactLabel")}</label>
          </div>
          <PhoneInput
            international
            defaultCountry="US"
            value={phone}
            placeholder={t("BookAppointment.ContactPlaceholder")}
            onChange={setPhone}
          />
        </div>
        <div className="input-content bookNow" onClick={handleInstantCall}>
          <button type="submit">
            {isLoading ? (
              <ThreeDots
                visible={true}
                height="20"
                width="40"
                color="#fff"
                radius="4"
                ariaLabel="three-dots-loading"
              />
            ) : (
              t("BookAppointment.BookNowButton")
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BookAppointment;
